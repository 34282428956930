import React, { useEffect, useRef } from 'react';

import PodcastImage from './PodcastImage';

function Player(props) {
	const audioElement = useRef(null);

	const isFirstRun = useRef(true);

	useEffect(() => {
		try {
			if (audioElement && audioElement.current) {
				audioElement.current.pause();
				audioElement.current.load();

				if (isFirstRun.current) {
					isFirstRun.current = false;
					return;
				}
				audioElement.current.play();
			}
		}
		catch(exception) {
			console.log('Podfriend Widget: Request to podcast audio failed');
		}
	},[props.playingEpisode]);

	const addUserAgentToUrl = (fileUrl) => {
		try {
			const resourceUrl = new URL(fileUrl);
			resourceUrl.searchParams.delete('_from');
			resourceUrl.searchParams.append('_from','podfriend.com');
			return resourceUrl.toString();
		}
		catch (exception) {
			console.log('addUserAgentToUrlException');
			console.log(exception);
			try {
				var fallbackUrl = fileUrl;
				if (fallbackUrl.includes('?')) {
					fallbackUrl += '&_from=podfriend.com'
				}
				else {
					fallbackUrl += '?_from=podfriend.com';
				}
				return fallbackUrl;
			}
			catch (exception2) {
				return fileUrl;
			}
		}
	};

	return (
		<div className="player">
			{ props.playingEpisode === false &&
				<div>
					Loading episode...
				</div>
			}
			{ props.playingEpisode !== false &&
				<>
					<PodcastImage className="cover" podcastId={props.playingEpisode.feedId} imageErrorText={props.playingEpisode.title} width={70} height={70} src={props.playingEpisode.image ? props.playingEpisode.image : props.playingEpisode.feedImage} alt={props.playingEpisode.title + ' thumbnail'} />
					<div className="podcastInfo">
						<div className="title">
							{props.playingEpisode.title}
						</div>
						<div className="playerControls">
							<audio controls ref={audioElement} preload="metadata">
							<source src={addUserAgentToUrl(props.playingEpisode.enclosureUrl)} type={props.playingEpisode.enclosureType} />
								Your browser does not support the audio element.
							</audio>
						</div>
					</div>
				</>
			}
		</div>
	);
}
export default Player;