import React from 'react';

import {
	HashRouter as Router,
	Switch,
	Route
} from "react-router-dom";

// import PodcastSearchPane from './SearchPane';

import Widget from './Widget';

import './App.css';

function App(props) {
	try {
		const rawParams = String(window.location).split('?')[1].split('&');

		var params = [];
		for(var i=0;i<rawParams.length;i++) {
			var [ key, value ] = rawParams[i].split('=');
			params[key] = value;
		}
		
		const validateHex = (num) => {
			if (!num) { return false; }
			if (num.length > 7) { return false; }
			return Boolean('#' + num.match(/^0x[0-9a-f]+$/i))
		}
		
		let showPodcastInfo = params['showPodcastInfo'] === 'true';
		let episodeCount = params['episodeCount'];
		let widgetType = params['widgetType'];
		let activeEpisodeId = params['activeEpisodeId'];
		
		if (episodeCount <= 0 || episodeCount > 1000) {
			episodeCount = 5;
		}

		const theme = {
			primaryColor: '#' + (validateHex(params.primaryColor) ? params.primaryColor : '0176e5'),
			secondaryColor: '#' + (validateHex(params['secondaryColor']) ? params['secondaryColor'] : 'FFFFFF'),
			backgroundColor: '#' + (validateHex(params['backgroundColor']) ? params['backgroundColor'] : 'FFFFFF'),
			textColor: '#' + (validateHex(params['textColor']) ? params['textColor'] : '000000')
		};

		return (
			<Router>
				<Switch>
					<Route path='/podcast/:podcastId/:episodeId?'>
						<Widget theme={theme} showPodcastInfo={showPodcastInfo} widgetType={widgetType} activeEpisodeId={activeEpisodeId} episodeCount={episodeCount} />
					</Route>
				</Switch>
			</Router>
		);
	}
	catch (exception) {
		return (
			<div style={{ color: '#FFFFFF' }}>
				It looks like the podcast widget was not implemented correctly.<br />
				Please go to <a href="https://widgetsetup.podfriend.com" style={{ color: '#FFFFFF' }}>Podfriend widget setup</a> to generate new code.
			</div>
		);
	}
}

export default App;
