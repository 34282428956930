import React, { useState, useEffect, useRef } from 'react';

import { FaPlay } from "react-icons/fa";

function EpisodeList({ playEpisode, pauseEpisode, episodes, playingEpisode, widgetType, showPodcastInfo, theme }) {
	const stripTags = (content) => {
		return content.replace(/<[^>]*>?/gm, '');
	}

	const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

	const resizeHandler = () => {
		setWindowSize({ width: window.innerWidth, height: window.innerHeight });
	};

	useEventListener('resize',resizeHandler);

	if (widgetType === 'episode') {
		return null;
	}

	const removeHeight = showPodcastInfo ? 200 : 110;

	return (
		<div className="episodeList" style={{ height: (windowSize.height - removeHeight), backgroundColor: theme.backgroundColor }}>
			{ episodes.items.map((episode,index) => {
				var isActive = playingEpisode.id === episode.id;
				var date = new Date(episode.datePublished * 1000);
				return (
					<div key={episode.id} className={'episode' + (isActive ? ' active' : '')} style={{ backgroundColor: isActive ? theme.primaryColor : '', color: isActive ? theme.secondaryColor : theme.textColor }}>
						{ playingEpisode.id === episode.id &&
							<div className="pauseIcon" onClick={() => { pauseEpisode(episode); }}>▌▌</div>
						}
						{ playingEpisode.id !== episode.id &&
							<div className="playIcon" onClick={() => { playEpisode(episode); }} style={{ backgroundColor: theme.primaryColor, color: theme.secondaryColor }}><FaPlay size="22" /></div>
						}
						<div className="episodeInfo">
							<div className="date">{date.toLocaleDateString("en-US")}</div>
							<div className="title">{episode.title}</div>
							<div className="description">{stripTags(episode.description)}</div>
						</div>
					</div>
				);
			}) }
		</div>
	);
}

function useEventListener(eventName, handler, element = window){
	// Create a ref that stores handler
	const savedHandler = useRef();
	
	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
	  savedHandler.current = handler;
	}, [handler]);
  
	useEffect(
	  () => {
		// Make sure element supports addEventListener
		// On 
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;
		
		// Create event listener that calls handler function stored in ref
		const eventListener = event => savedHandler.current(event);
		
		// Add event listener
		element.addEventListener(eventName, eventListener);
		
		// Remove event listener on cleanup
		return () => {
		  element.removeEventListener(eventName, eventListener);
		};
	  },
	  [eventName, element] // Re-run if eventName or element changes
	);
  };

export default EpisodeList;