import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import PodcastService from './PodcastService';

const PodcastInfo = () => {
	let { podcastId } = useParams();

	const [podcastInfo, setPodcastInfo] = useState(false);

	useEffect(() => {
		setPodcastInfo(false);
		PodcastService.lookUp(podcastId)
		.then((podcastInfo) => {
			console.log(podcastInfo);
			setPodcastInfo(podcastInfo);
		});
	},[podcastId]);

	return (
		<div className="podcastInfo">
			{ podcastInfo === false &&
				<div className="loading">
					<div>
						Loading podcast
					</div>
				</div>
			}
			{ podcastInfo !== false &&
				<div className="podcastInner">
					<img src={podcastInfo.feed.artwork ? podcastInfo.feed.artwork : podcastInfo.feed.image} alt={podcastInfo.feed.title + ' artwork'} className="cover" />
					<div className="podcastCoreInfo">
						<h1>{podcastInfo.feed.title}</h1>
						<h2>{podcastInfo.feed.author}</h2>
						<p>{podcastInfo.feed.description}</p>
					</div>
				</div>
			}
		</div>
	);
};

export default PodcastInfo;