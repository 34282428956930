import React, { useState } from 'react';

import PodcastPane from './PodcastPane';
import PoweredBy from './PoweredBy';

import Player from './Player';
import PodcastInfo from './PodcastInfo';

const Widget = ({ showPodcastInfo, widgetType, episodeCount, activeEpisodeId, theme }) => {
	const [playingEpisode, setPlayingEpisode] = useState(false);
	const [isPaused, setIsPaused] = useState(false);

	const pauseEpisode = () => {
		setIsPaused(true);
	}
	const playEpisode = (episode) => {
		setPlayingEpisode(episode);
	}

	return (
		<div className="widget">
			{ showPodcastInfo &&
				<PodcastInfo theme={theme} />
			}
			<Player theme={theme} playingEpisode={playingEpisode} isPaused={isPaused} />
			<PodcastPane theme={theme} playEpisode={playEpisode} pauseEpisode={pauseEpisode} playingEpisode={playingEpisode} showPodcastInfo={showPodcastInfo} widgetType={widgetType} episodeCount={episodeCount} activeEpisodeId={activeEpisodeId} />
			<PoweredBy />
		</div>
	);
}
export default Widget;