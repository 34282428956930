import React, { useState, useEffect } from 'react';

const STATUS_PRELOAD = 1;
const STATUS_USE_STATICALLY_TMP = 2;
const STATUS_USE_STATICALLY_FULL = 3;
const STATUS_USE_ORIGINAL = 4;
const STATUS_ERROR = -1;

const imageTempSize = 20;
const imageTempQuality = 40;

const fullImageQuality = 80;

var randomColor = require('randomcolor');

const loadImage = (src) => {
	return new Promise((resolve,reject) => {
		const image = new Image(src);
		image.onerror = () => {
			return reject();
		};
		image.onload = () => {
			return resolve();
		};
		image.src = src;
	});
}
// https://www.podfriend.com/img/app-preview.png
// https://cdn.statically.io/img/podfriend.com/img/app-preview.png?w=20&h=20
const PodcastImage = React.memo(({ podcastId = false, src, width = 100, height = 100, retina= true, alt = "", className = "", imageErrorText = "" }) => {
	const staticallyImgTempSrc = 'https://cdn.statically.io/img/' + (src.split('://')[1].split('?')[0]) + '?w=' + imageTempSize + '&h=' + imageTempSize + '&quality=' + imageTempQuality;
	const staticallyImgFullSrc = 'https://cdn.statically.io/img/' + (src.split('://')[1].split('?')[0]) + '?w=' + (retina ? width * 2 : width) + '&h=' + (retina ? height * 2 : height) + '&quality=' + fullImageQuality;

	const [status, setStatus] = useState(STATUS_PRELOAD);
	const [currentSrc, updateSrc] = useState(staticallyImgTempSrc);

	useEffect(() => {
		setStatus(STATUS_PRELOAD);

		// Let's load the temp image
		loadImage(staticallyImgTempSrc)
		.then(() => {
			setStatus(STATUS_USE_STATICALLY_TMP);
			// The temp image loaded, now let's load the large one
			loadImage(staticallyImgFullSrc)
			.then(() => {
				updateSrc(staticallyImgFullSrc);
				setStatus(STATUS_USE_STATICALLY_FULL);
			})
			.catch(() => {
				// Somehow the large version failed. That's weird. But let's fall back to our div
				setStatus(STATUS_ERROR);
			});
		})
		.catch(() => {
			// Yikes, statically failed. Let's try the original.
			loadImage(src)
			.then(() => {
				updateSrc(src);
				setStatus(STATUS_USE_ORIGINAL);
			})
			.catch(() => {
				// Yikes, the full image failed as well. Fall back to our div
				setStatus(STATUS_ERROR);
			});
		});
	}, [src, staticallyImgTempSrc, staticallyImgFullSrc])

	if (status === STATUS_ERROR || status === STATUS_PRELOAD) {
        var customRandomColor = randomColor({
            seed: podcastId ? podcastId : src,
            luminosity: 'bright'
        });

		return (
			<div
				style={{
					backgroundColor: customRandomColor,
					width: width,
					height: height,
					minWidth: width,
					maxWidth: width,
					minHeight: height,
					maxHeight: height,
					overflow: 'hidden',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',
					color: '#FFFFFF',
					fontSize: 12
				}}
				className={className}>
				{imageErrorText}
			</div>
		);
	}
	else {
		return (
			<img
				src={currentSrc}
				style={{
					width: width, height: height, minWidth: width, maxWidth: width, minHeight: height, maxHeight: height,
					opacity: status === STATUS_USE_STATICALLY_TMP ? 0.5 : 1,
					transition: "opacity .15s linear"
				}}
				alt={alt}
				className={className}
			/>
		)
	}
});

export default PodcastImage;