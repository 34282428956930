import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import LoadingIndicator from './resources/loading.svg';

import PodcastService from './PodcastService';
import EpisodeList from './EpisodeList';

function PodcastPane({ episodeCount, activeEpisodeId, playEpisode, pauseEpisode, playingEpisode, showPodcastInfo, widgetType, theme }) {
	let { podcastId } = useParams();

	const [episodes, setEpisodes] = useState(false);

	useEffect(() => {
		if (activeEpisodeId) {
			PodcastService.retrieveOneEpisode(activeEpisodeId)
			.then((episode) => {
				console.log(episode);
				if (episode && episode.episode && episode.episode.id) {
					playEpisode(episode.episode);
				}
				setEpisodes([episode.episode]);
			});
		}
		else {
			PodcastService.retrieveEpisodes(podcastId,episodeCount)
			.then((episodes) => {
				if (episodes && episodes.items && episodes.items.length > 0) {
					playEpisode(episodes.items[0]);
				}
				setEpisodes(episodes);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[podcastId, episodeCount]);

	return (
		<div className="widgetContainer">
			{ episodes === false  &&
				<div className="loading">
					<span>
						Loading Episodes
					</span>
					<img src={LoadingIndicator} alt="Loading indicator" className="loadingIndicator" />
				</div>
			}
			{ episodes !== false &&
				<EpisodeList theme={theme} showPodcastInfo={showPodcastInfo} widgetType={widgetType} activeEpisodeId={activeEpisodeId} playEpisode={playEpisode} pauseEpisode={pauseEpisode} playingEpisode={playingEpisode} episodes={episodes} />
			}
		</div>
	);
}
export default PodcastPane;